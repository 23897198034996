<template>
  <div style="padding:10px">
    <el-row class="row">
      <TableTitleComponent title="打印机程序下载"/>
      <el-card shadow="never">
        <el-link href="https://lenovo.emaxtek.com/fileServer/printer/doc.zip">打印机配置参考文档</el-link>
        <el-link href="https://lenovo.emaxtek.com/fileServer/printer/zebra_printer_driver.zip">打印机驱动程序下载</el-link>
        <el-link href="https://lenovo.emaxtek.com/fileServer/printer/jdk1.8.zip">JDK下载</el-link>
        <el-link href="https://lenovo.emaxtek.com/fileServer/printer/printService.zip">打印小程序下载(zip)</el-link>
        <el-link href="https://lenovo.emaxtek.com/fileServer/printer/print-service-1.0.jar">打印小程序下载(jar)</el-link>
      </el-card>
    </el-row>
    <el-row class="row">
      <TableTitleComponent title="打印机连接测试"/>
      <el-card shadow="never">
        <el-button type="primary" size="mini" :loading="loading" @click="print('assetSn')">测试打印资产码</el-button>
        <el-button type="primary" size="mini" :loading="loading" @click="print('store1')">测试打印仓库标签(短内容)</el-button>
        <el-button type="primary" size="mini" :loading="loading" @click="print('store2')">测试打印仓库标签(长内容)</el-button>
        <el-button type="primary" size="mini" :loading="loading" @click="print('trouble')">测试打印不良标签</el-button>
        <el-button type="primary" size="mini" :loading="loading" @click="print('calibration1')">测试校色屏旧标签</el-button>
        <el-button type="primary" size="mini" :loading="loading" @click="print('calibration2')">测试校色屏新标签</el-button>
      </el-card>
    </el-row>
    <el-row class="row">
      <TableTitleComponent title="浏览器证书下载"/>
      <el-card shadow="never">
        <el-link href="https://lenovo.emaxtek.com/fileServer/printer/ssl_doc.zip">证书安装参考文档</el-link>
        <el-link href="https://lenovo.emaxtek.com/fileServer/printer/ca.zip">证书下载</el-link>
      </el-card>
    </el-row>

  </div>
</template>

<script>
import TableTitleComponent from "@/components/TableTitleComponent";

export default {
  name: "Help",
  components: {TableTitleComponent},
  data() {
    return {
      loading: false
    }
  },
  methods: {
    print(type) {
      if (this.$socket.notOk()) {
        return this.$message.error('未成功连接打印机小程序');
      }
      this.loading=true
      let msg = '{"requestId":100,"printType":"asset","printer":"ZDesigner 110Xi4 600 dpi,Zebra 110Xi4 (600 dpi)","printVO":{"materialCode":"5B20S43884","assetSn":"8S5B20S4388412345678901","materialSource":"P","supplierCode":"M167","printDate":"214Q3","diff1":"A","diff2":"B","out":true}}';
      if (type == 'store1') {
        msg = '{"requestId":101,"printType":"store","printer":"ZDesigner GK888t (EPL)","printVO":{"chargeLineName":"笔记本","orderTypeName":"采购订单","supplierName":"上海迈创电子有限公司","testResultName":"外观不良","disposeOpinionName":"待索赔","storeTypeName":"S联想服务上海索赔库","noRmaReason":"FRAUD","planCategory":"LCD"}}';
      } else if (type == 'store2') {
        msg = '{"requestId":101,"printType":"store","printer":"ZDesigner GK888t (EPL)","printVO":{"chargeLineName":"THINK笔记本","orderTypeName":"BUFFER免费入库订单(不需归还)","supplierName":"阳光雨露信息技术服务（北京）有限公司-OOW","testResultName":"有电性能故障, 故障属实","disposeOpinionName":"采购不良","storeTypeName":"S联想服务上海索赔库—Samsung SSD/DIMM（固态硬盘/内存）","noRmaReason":"FRAUD","planCategory":"无线键盘鼠标套装"}}';
      } else if (type == 'trouble') {
        msg = '{"requestId":102,"printType":"trouble","printer":"ZDesigner GK888t (EPL)","printVO":{"testTypeName":"退库","test1TroubleCode":"AA003","test1TroubleReason":"线头脱落","test2TroubleCode":"AA004","test2TroubleReason":"无法开机","testResult":"3","disposeOpinionName":"待维修","testUserName":"王晓曼","testDate":"20210419"}}'
      } else if (type == 'calibration1') {
        msg = '{"requestId":102,"printType":"calibration","printer":"ZDesigner GK888t (EPL)","printVO":{"assetSn":"8S5B20S4388412345678901"}}';
      } else if (type == 'calibration2') {
        msg = '{"requestId":102,"printType":"calibration","printer":"ZDesigner GK888t (EPL)","printVO":{"type":"2","assetSn":"8S5B20S4388412345678901"}}'
      }
      this.$socket.send(msg)
      this.loading=false
    }
  }
}
</script>

<style scoped>
.el-row {
  padding-top: 20px;
}

.el-link {
  margin-right: 20px;
}
</style>